<template>
  <div :class="$style.component">
    <h2
      id="pin-head"
      data-aos="fade"
      :class="$style.heading"
    >
      Licenses and Eligibility
    </h2>

    <div id="trigger1" />

    <div :class="$style.col">
      <div
        id="pin-usa"
        :class="[$style.card, $style.usa]"
      >
        <div :class="$style.content">
          <img
            src="../assets/images/usa.png"
            alt=""
            :class="$style.image"
          >

          <h4 :class="$style.title">
            Global coverage of 180+ countries and unprecedented 48 US states
          </h4>

          <p :class="$style.text">
            Buy & sell cryptocurrencies no matter where you are
          </p>
        </div>
      </div>
    </div>

    <div :class="$style.col">
      <div
        id="pin-us-treasury"
        :class="[$style.card, $style.usTreasury]"
      >
        <div :class="$style.content">
          <img
            src="../assets/images/us-treasury.png"
            alt=""
            :class="$style.image"
          >

          <h4 :class="$style.title">
            FinCEN Department of the Treasury, United States of America
          </h4>

          <p :class="$style.text">
            Money Service Business Registration:<br />
            <span :class="$style.highlight">31000175037491</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import ScrollMagic from 'scrollmagic';

let controller;
let isDesktop = window.innerWidth > 999;

const init = () => {
  controller = new ScrollMagic.Controller();

  const duration = 500;
  const usTreasury = document.getElementById('pin-us-treasury');
  usTreasury.style.marginTop = '-250px';

  new ScrollMagic.Scene({
    triggerElement: '#trigger1',
    duration
  })
    .setPin('#pin-head', { pushFollowers: false })
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: '#trigger1',
    duration
  })
    .setPin('#pin-usa')
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: '#trigger1',
    duration
  })
    .setTween('#pin-usa', 1, { opacity: 0.5, scale: 0.95 })
    .addTo(controller);
};

const destroy = () => {
  controller.destroy(true);

  const usTreasury = document.getElementById('pin-us-treasury');
  usTreasury.style.marginTop = '';

  const usa = document.getElementById('pin-usa');
  usa.style.opacity = '';
  usa.style.transform = '';
};

export default {
  name: 'LicensesCards',
  mounted: () => {
    if (isDesktop) {
      init();
    }
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler: () => {
      const value = window.innerWidth > 999;

      if (value !== isDesktop) {
        isDesktop = value;

        if (value) {
          init();
        } else {
          destroy();
        }
      }
    }
  }
};
</script>

<style module lang="scss">
  .component {
    @include mediaMd {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 -10px;
    }

    @include mediaLgMin {
      max-width: 650px;
      margin: 0 auto;
    }
  }

  .heading {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 40px;
    font-weight: 900;
    font-size: 40px;
    line-height: 1.1;
    text-align: center;

    @include mediaLgMin {
      margin-bottom: 80px;
      font-size: 60px;
    }
  }

  .card {
    padding: 35px 30px 24px;
    text-align: center;
    background: linear-gradient(180deg, lighten($blackL, 1%) 0%, lighten($black, 4.7%) 100%);
    border-radius: 6px;

    @include mediaXs {
      transition-delay: 0s !important;
    }
  }

  .col {
    flex: 0 0 50%;

    @include mediaMd {
      padding: 0 10px;
    }

    & + .col {
      margin-top: 20px;

      @include mediaMd {
        margin-top: 0;
      }

      @include mediaLgMin {
        margin-top: 30px;
      }
    }
  }

  .content {
    max-width: 280px;
    margin: 0 auto;
  }

  .image {
    margin-bottom: 25px;
  }

  .title {
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
  }

  .highlight {
    color: $green;
  }

  .usa {
    .image {
      width: 85px;

      @include mediaMd {
        margin-bottom: 31px;
        margin-top: 8px;
      }
    }
  }

  .usTreasury {
    position: relative;
    z-index: 2;

    .image {
      width: 66px;
    }
  }
</style>
