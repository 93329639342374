<template>
  <div :class="$style.component">
    <div :class="$style.header">
      <Header />
    </div>

    <div :class="$style.content">
      <PromoBlock />

      <FeaturesBlock />

      <LicensesAndEligibilityBlock />

      <BuyBitcoinBlock />
    </div>

    <div :class="$style.footer">
      <MoreCryptoBlock />

      <Footer />
    </div>

    <AcceptCookies />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import PromoBlock from './components/PromoBlock.vue';
import FeaturesBlock from './components/FeaturesBlock.vue';
import LicensesAndEligibilityBlock from './components/LicensesAndEligibilityBlock.vue';
import BuyBitcoinBlock from './components/BuyBitcoinBlock.vue';
import MoreCryptoBlock from './components/MoreCryptoBlock.vue';
import Footer from './components/Footer.vue';
import AcceptCookies from './components/AcceptCookies.vue';

export default {
  name: 'App',
  components: {
    Header,
    PromoBlock,
    FeaturesBlock,
    LicensesAndEligibilityBlock,
    BuyBitcoinBlock,
    MoreCryptoBlock,
    Footer,
    AcceptCookies
  }
};
</script>

<style module lang="scss">
  .component {
    position: relative;
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .footer {
    background-image: url("./assets/images/footer-bg.jpg");
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
