<template>
  <div :class="$style.component">
    <div :class="$style.video">
      <img
        alt=""
        role="presentation"
        src="../assets/images/cover.jpg"
      />
      <video
        autoplay
        muted
        loop
        playsinline
        src="../assets/videos/cover.mp4"
      />
    </div>
    <div :class="$style.content">
      <Container>
        <div :class="$style.score">
          <img
            src="../assets/images/star.svg"
            alt=""
            :class="$style.star"
          >

          <div :class="$style.scoreNumber">
            <span>4.5</span>/5
          </div>

          <div :class="$style.scoreTitle">
            Excelent<br />on <span>Trustpilot</span>
          </div>
        </div>

        <h1 :class="$style.heading">
          Buy Bitcoin instantly
        </h1>

        <div :class="$style.form">
          <ConvertForm />
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
import Container from './Container.vue';
import ConvertForm from './ConvertForm.vue';

export default {
  name: 'PromoBlock',
  components: {
    Container,
    ConvertForm
  }
};
</script>

<style module lang="scss">
  .component {
    position: relative;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;

    img,
    video {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      min-width: 100%;
      min-height: 100%;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    padding: 120px 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(0deg, $black 13.32%, rgba($black, 0.6) 95.61%);

    @include mediaSmMin {
      padding: 200px 0 120px;
    }

    @include mediaLgMin {
      padding: 260px 0 140px;
    }
  }

  .score {
    position: absolute;
    top: 30px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);

    @include mediaSmMin {
      top: 85px;
    }

    @include mediaLgMin {
      top: 52px;
    }
  }

  .star {
    margin-bottom: 5px;
    width: 20px;

    @include mediaSmMin {
      margin-bottom: 7px;
      width: 30px;
    }

    @include mediaLgMin {
      margin-bottom: 8px;
      width: 47px;
    }
  }

  .scoreNumber {
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.1;
    color: rgba($white, 0.4);

    @include mediaSmMin {
      font-size: 15px;
    }

    @include mediaLgMin {
      font-size: 23px;
    }

    span {
      color: $white;
    }
  }

  .scoreTitle {
    font-size: 10px;
    line-height: 1.1;
    color: rgba($white, 0.8);

    @include mediaSmMin {
      font-size: 12px;
    }

    @include mediaLgMin {
      font-size: 18px;
    }

    span {
      color: $white;
    }
  }

  .heading {
    margin: 0 auto 43px;
    max-width: 310px;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-align: center;

    @include mediaSmMin {
      margin-bottom: 100px;
      max-width: 455px;
    }

    @include mediaLgMin {
      max-width: 900px;
      margin-bottom: 120px;
      font-size: 80px;
    }
  }

  .form {
    @include mediaXs {
      transition-delay: 0s !important;
    }
  }
</style>
