<template>
  <div :class="$style.component">
    <Container>
      <h2
        data-aos="fade"
        :class="$style.heading"
      >
        With Paybis you can also
      </h2>
    </Container>

    <div
      data-aos="fade-up"
      data-aos-delay="200"
      :class="$style.outerGrad"
    >
      <div :class="$style.outerRow">
        <div
          v-for="(rowValue, rowIndex) in coins"
          :key="rowIndex"
          :class="$style.outerCol"
        >
          <div :class="$style.innerGrad">
            <div :class="$style.innerRow">
              <div
                v-for="(colValue, colIndex) in rowValue"
                :key="colIndex"
                :class="$style.innerCol"
              >
                <div :class="$style.grad">
                  <CoinLink
                    v-for="coin in colValue"
                    :key="coin.symbol"
                    :name="coin.name"
                    :symbol="coin.symbol"
                    :label="coin.label"
                    :url="coin.url"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Container from './Container.vue';
import CoinLink from './CoinLink.vue';

export default {
  name: 'MoreCryptoBlock',
  components: {
    Container,
    CoinLink
  },
  data: () => ({
    coins: [
      [
        [
          {
            symbol: 'ETH',
            name: 'Ethereum',
            label: 'Buy Ethereum (ETH)',
            url: '/'
          },
          {
            symbol: 'TRX',
            name: 'Tron',
            label: 'Buy Tron (TRX)',
            url: '/'
          }
        ],
        [
          {
            symbol: 'USDT',
            name: 'Tether',
            label: 'Buy Tether (USDT)',
            url: '/'
          },
          {
            symbol: 'LTC',
            name: 'Litecoin',
            label: 'Buy Litecoin (LTC)',
            url: '/'
          }
        ]
      ],
      [
        [
          {
            symbol: 'BNB',
            name: 'Binance Coin',
            label: 'Buy Binance Coin (BNB)',
            url: '/'
          },
          {
            symbol: 'BCH',
            name: 'Bitcoin Cash',
            label: 'Buy Bitcoin Cash (BCH)',
            url: '/'
          }
        ],
        [
          {
            symbol: 'XRP',
            name: 'Ripple',
            label: 'Buy Ripple (XRP)',
            url: '/'
          },
          {
            symbol: 'XLM',
            name: 'Stellar',
            label: 'Buy Stellar (XLM)',
            url: '/'
          }
        ]
      ]
    ]
  })
};
</script>

<style module lang="scss">
  .component {
    padding-bottom: 100px;

    @include mediaSmMin {
      padding-bottom: 120px;
    }

    @include mediaLgMin {
      padding-bottom: 140px;
    }
  }

  .heading {
    margin-bottom: 40px;
    font-weight: 900;
    font-size: 40px;
    line-height: 1.1;
    text-align: center;

    @include mediaLgMin {
      margin-bottom: 60px;
      font-size: 60px;
    }
  }

  .outerRow,
  .innerRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  .outerGrad {
    @include mediaXs {
      border-radius: 6px;
      margin: 0 20px;
      padding: 16px 0;
      background: $blackGrad;
      transition-delay: 0s !important;
    }

    @include mediaSmMin {
      padding: 0 15px;
    }

    @include mediaLgMin {
      padding: 0 60px;
    }
  }

  .innerGrad {
    @include mediaSmMin {
      padding: 16px 0;
      border-radius: 6px;
      background: $blackGrad;
    }

    @include mediaXl {
      padding: 0;
      border-radius: 0;
      background: none;
    }
  }

  .outerCol {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 10px;

    @include mediaSmMin {
      flex: 0 0 50%;
    }
  }

  .innerCol {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 10px;

    @include mediaXl {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .grad {
    @include mediaXl {
      padding: 16px 0;
      background: $blackGrad;
      border-radius: 6px;
    }
  }

  .coin {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    text-decoration: none;

    &:hover,
    &:focus {
      .label {
        border-color: $white;
      }
    }

    @include mediaSmMin {
      max-width: 240px;
      margin: 0 auto;
      justify-content: flex-start;
    }

    @include mediaXl {
      margin: 0;
      max-width: initial;
      padding: 10px 24px;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    margin-right: 12px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .label {
    display: inline-block;
    font-size: 18px;
    line-height: 1.5;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: $gray;
    transition: border-color 0.2s ease;
  }
</style>
