<template>
  <div :class="$style.container">
    <div :class="$style.list">
      <div
        v-for="item in items"
        data-aos="fade-up"
        :data-aos-delay="item.key * 200"
        :key="item.key"
        :class="$style.item"
      >
        <div :class="$style.box">
          <div :class="$style.content">
            <h4 :class="$style.title">
              {{item.title}}
            </h4>

            <p :class="$style.text">
              {{item.text}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesList',

  data: () => ({
    items: [
      {
        key: 1,
        title: 'Trusted exchange',
        text: 'Loved by more than 1,000,000 happy customers.'
      },
      {
        key: 2,
        title: 'Lightning-fast service',
        text: 'Get verified and receive your Bitcoins in 5 minutes.'
      },
      {
        key: 3,
        title: 'Best offer on the market',
        text: 'Buy Bitcoin at true cost - it cannot get any better than this!'
      }
    ]
  })
};
</script>

<style module lang="scss">
  .container {
    margin: 0 auto;
    max-width: 1046px;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .list {
    @include mediaSmMin {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      min-width: 960px;
      padding: 0 12px;
    }
  }

  .item {
    padding: 0 12px;

    @include mediaXs {
      transition-delay: 0s !important;
    }

    @include mediaSmMin {
      flex: 0 0 33.3333%;
    }

    & + .item {
      @include mediaXs {
        margin-top: 10px;
      }
    }
  }

  .box {
    position: relative;
    min-height: 100%;
    width: 100%;
    padding: 30px 20px;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
    }

    &:before {
      z-index: 0;
      border-style: solid;
      border-width: 1px;
      border-color: $white;
      border-radius: 6px;
    }

    &:after {
      z-index: 1;
      background: linear-gradient(0deg, $black, transparent);
    }
  }

  .content {
    position: relative;
    z-index: 2;
    line-height: 28px;
  }

  .title {
    margin-bottom: 1em;
    font-weight: 600;
    font-size: 22px;

    @include mediaLgMin {
      font-size: 24px;
    }
  }

  .text {
    font-size: 16px;
    padding-right: 35px;

    @include mediaLgMin {
      font-size: 18px;
    }
  }

</style>
