<template>
  <div v-bind:class="[$style.component, $style[name]]">
    <div :class="$style.icon">
      <img
        v-bind:alt="`${name} icon`"
        v-bind:src="require(`../assets/images/socials/${name}.svg`)"
      >
    </div>

    <div :class="$style.count">
      {{formatValue(value)}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialCounter',
  props: {
    name: {
      type: String,
      required: true,
      validator: (value) => [
        'facebook',
        'twitter',
        'instagram',
        'youtube',
        'reddit',
        'podcast'
      ].includes(value)
    },
    value: {
      type: Number,
      required: false,
      default: 0
    }
  },
  methods: {
    formatValue: (value) => {
      const notation = Intl.NumberFormat('en', { notation: 'compact' });
      return notation.format(value);
    }
  }
};
</script>

<style module lang="scss">
.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 2px solid;
}

.icon {
  display: flex;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;

  img {
    user-select: none;
    pointer-events: none;
  }
}

.count {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;
}

.facebook {
  color: $facebook;
  border-color: $facebook;
}

.twitter {
  color: $twitter;
  border-color: $twitter;
}

.instagram {
  color: $instagram;
  border-color: $instagram;
  border-image-source: linear-gradient(81.21deg, #FFD378 8.5%, #EC4C4C 34.04%, #BD318E 66.3%, #545ECE 94.53%);;
  border-image-slice: 2;
}

.youtube {
  color: $youtube;
  border-color: $youtube;
}

.reddit {
  color: $reddit;
  border-color: $reddit;
}

.podcast {
  color: $podcast;
  border-color: $podcast;
}
</style>
