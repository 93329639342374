<template>
  <transition
    appear
    :duration="500"
  >
    <div
      v-if="open"
      :class="$style.component"
    >
      <div :class="$style.text">
        We use cookies to ensure you get the best experience on our website
      </div>

      <div :class="$style.bottom">
        <button
          type="button"
          :class="$style.button"
          @click="close"
        >
          Accept and close
        </button>

        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          :class="$style.link"
        >
          Policies
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import ls from 'local-storage';

const LS_KEY = 'paybis-accept-cookies';

export default {
  name: 'AcceptCookies',
  data: () => ({
    open: false
  }),
  methods: {
    close() {
      this.open = false;
      ls(LS_KEY, 1);
    }
  },
  mounted() {
    const value = ls(LS_KEY);

    if (value) {
      return;
    }

    this.open = true;
  }
};
</script>

<style module lang="scss">
  .component {
    position: fixed;
    left: 14px;
    bottom: 14px;
    z-index: 100;
    width: 320px;
    max-width: 75%;
    padding: 16px;
    background-color: $white;
    border-style: solid;
    border-width: 1px;
    border-color: $black;
    border-radius: 6px;
    box-shadow: 0px 4px 20px rgba($black, 0.08);

    @include mediaMdMin {
      left: 30px;
      bottom: 30px;
    }

    @include mediaXl {
      left: 60px;
      bottom: 50px;
    }

    &:global(.v-enter-active) {
      opacity: 0;
      transform: translateX(-100%);
      transition: opacity .5s ease-in-out,transform .5s ease-in-out;
    }

    &:global(.v-enter-to) {
      opacity: 1;
      transform: translateX(0);
    }

    &:global(.v-leave-active) {
      opacity: 1;
      transform: translateX(0);
      transition: opacity .5s ease-in-out, transform .5s ease-in-out;
    }

    &:global(.v-leave-to) {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  .text {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: $black;
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .button {
    @include resetButtonStyle;

    margin-right: 20px;
    padding: 8px 12px;
    font-weight: 600;
    color: $white;
    background-color: $black;
    border-radius: 4px;
    transition: opacity 0.15s ease;

    &:hover {
      opacity: 0.85;
    }
  }

  .link {
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
    color: $black;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: rgba($black, 0.15);
    transition: border-color 0.15s ease;

    &:hover {
      border-color: $black;
    }
  }
</style>
