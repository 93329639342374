<template>
  <div :class="$style.component">
    <Container>
      <LicensesCards />
    </Container>
  </div>
</template>

<script>
import Container from './Container.vue';
import LicensesCards from './LicensesCards.vue';

export default {
  name: 'LicensesAndEligibilityBlock',
  components: {
    Container,
    LicensesCards
  }
};
</script>

<style module lang="scss">
  .component {
    padding-bottom: 100px;

    @include mediaSmMin {
      padding-bottom: 120px;
    }

    @include mediaLgMin {
      padding-bottom: 140px;
    }
  }
</style>
