<template>
  <div :class="$style.component">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Container'
};
</script>

<style module lang="scss">
  .component {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;

    @include mediaMd {
      max-width: 736px;
    }

    @include mediaLgMin {
      max-width: 1120px;
    }
  }
</style>
