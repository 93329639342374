import Vue from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import App from './App.vue';

import './assets/scss/reset.scss';
import './assets/scss/fonts.scss';
import './assets/scss/main.scss';

new Vue({
  created() {
    AOS.init({
      once: true,
      duration: 1000,
      easing: 'ease-out'
    });
  },
  render: (h) => h(App)
}).$mount('#app');
