<template>
  <div :class="$style.component">
    <Container>
      <h2
        data-aos="fade"
        :class="$style.heading"
      >
        Buy Bitcoin
      </h2>

      <div
        data-aos="fade"
        data-aos-delay="300"
        :class="$style.form"
      >
        <ConvertForm />
      </div>
    </Container>
  </div>
</template>

<script>
import Container from './Container.vue';
import ConvertForm from './ConvertForm.vue';

export default {
  name: 'BuyBitcoinBlock',
  components: {
    Container,
    ConvertForm
  }
};
</script>

<style module lang="scss">
  .component {
    padding-bottom: 100px;

    @include mediaSmMin {
      padding-bottom: 120px;
    }

    @include mediaLgMin {
      padding-bottom: 140px;
    }
  }

  .heading {
    margin-bottom: 40px;
    font-weight: 900;
    font-size: 40px;
    line-height: 1.1;
    text-align: center;

    @include mediaLgMin {
      margin-bottom: 60px;
      font-size: 60px;
    }
  }

  .form {
    @include mediaXs {
      transition-delay: 0s !important;
    }
  }
</style>
