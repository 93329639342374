<template>
  <div :class="$style.component">
    <div :class="$style.logo">
      <Logo />
    </div>

    <div :class="$style.langSwitch">
      <LangSwitch />
    </div>
  </div>
</template>

<script>
import Logo from './Logo.vue';
import LangSwitch from './LangSwitch.vue';

export default {
  name: 'Header',
  components: {
    Logo,
    LangSwitch
  }
};
</script>

<style module lang="scss">
  .component {
    width: 100%;
    padding: 30px 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mediaSmMin {
      padding: 30px;
    }

    @include mediaLgMin {
      padding: 60px;
    }
  }

  .logo {
    width: 68px;

    @include mediaSmMin {
      width: 100px;
    }
  }
</style>
