<template>
  <div :class="$style.component">
    <div :class="$style.row">
      <div :class="$style.col">
        <div :class="$style.contacts">
          Contacts
        </div>

        <a
          :class="$style.email"
          v-bind:href="`mailto:${email}`"
          rel="noopener noreferrer"
          target="_blank"
        >
          {{email}}
        </a>

        <p :class="$style.copyright">
          Paybis LTD, 1 West Regent Street, Glasgow, Scotland, G2 1RW © 2014-2021 Paybis.com, all rights reserved.
        </p>
      </div>

      <div :class="$style.col">
        <ul :class="$style.list">
          <li
            v-for="social in socials"
            :key="social.name"
            :class="$style.item"
          >
            <SocialCounter
              :name="social.name"
              :value="social.value"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SocialCounter from './SocialCounter.vue';

export default {
  name: 'Footer',
  components: {
    SocialCounter
  },
  data: () => ({
    email: 'support@paybis.com',
    socials: [
      { name: 'facebook', value: 9801 },
      { name: 'twitter', value: 13091 },
      { name: 'instagram', value: 2023 },
      { name: 'youtube', value: 1363 },
      { name: 'reddit', value: 1325 },
      { name: 'podcast', value: 860 }
    ]
  })
};
</script>

<style module lang="scss">
.component {
  width: 100%;
  padding: 30px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mediaSmMin {
    padding: 30px;
  }

  @include mediaLgMin {
    padding: 60px;
  }
}

.row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;

  @include mediaLgMin {
    flex-direction: row;
    align-items: flex-start;
  }
}

.col {
  @include mediaMdMax {
    &:first-child {
      margin-top: 44px;
    }
  }
}

.contacts {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;
  margin: 0 0 0.75em;
}

.email {
  display: inline-block;
  margin: 0 0 1.5em;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid transparent;

  &:hover,
  &:focus {
    border-bottom: 2px solid $white;
  }
}

.copyright {
  font-size: 12px;
  line-height: 1.2;
  color: $gray;

  @include mediaLgMax {
    max-width: 360px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -15px;

  @include mediaLgMin {
    min-width: 448px;
    margin: 0 -8px;
  }
}

.item {
  width: 33.333%;
  flex: 0 0 33.333%;
  padding: 0 15px;
  margin: 0 0 14px;

  @include mediaMdMin {
    width: 16.666%;
    flex: 0 0 16.666%;
  }

  @include mediaLgMin {
    width: 25%;
    flex: 0 0 25%;
    padding: 0 8px;
  }
}
</style>
