<template>
  <form
    :class="$style.component"
    @submit="onSubmit"
    novalidate
  >
    <div :class="$style.field">
      <CurrencyInput
        id="fromValue"
        name="fromValue"
        label="You spend"
        currency="USD"
        :value="fromValue"
        :error="errors.fromValue"
        @change="onChange"
      />
    </div>

    <div :class="$style.arrow" />

    <div :class="$style.field">
      <CurrencyInput
        id="toValue"
        name="toValue"
        label="You receive"
        currency="BTC"
        :value="toValue"
        :suggestions="toSuggestions"
        :error="errors.toValue"
        @change="onChange"
      />
    </div>

    <div :class="$style.submit">
      <Button type="submit">
        Buy Bitcoin
      </Button>
    </div>
  </form>
</template>

<script>
import CurrencyInput from './CurrencyInput.vue';
import Button from './Button.vue';

const RATE = 0.0000178568; // @TODO

export default {
  name: 'ConvertForm',
  components: {
    CurrencyInput,
    Button
  },
  data: () => ({
    fromValue: null,
    toValue: null,
    toSuggestions: [0.01, 0.02, 0.03, 0.05, 0.1, 0.2],
    errors: {}
  }),
  methods: {
    onChange(value, name) {
      if (name === 'fromValue') {
        this.fromValue = value;
        this.toValue = value * RATE; // @TODO watch for precision
      }

      if (name === 'toValue') {
        this.toValue = value;
        this.fromValue = value / RATE; // @TODO watch for precision
      }

      this.errors = {};
    },

    onSubmit(event) {
      event.preventDefault();

      // @TODO validation
      this.errors = {
        fromValue: 'The amount is greater than max allowed: 50 000 USD'
      };

      // @TODO redirect or something else
    }
  }
};
</script>

<style module lang="scss">
  .component {
    @include mediaSmMin {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include mediaLgMin {
      flex-wrap: nowrap;
    }
  }

  .field {
    @include mediaXs {
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
    }

    @include mediaSmMin {
      padding: 0 7px;
      max-width: 50%;
    }

    @include mediaLgMin {
      max-width: 350px;
    }
  }

  .arrow {
    margin-top: 22px;
    width: 50px;
    height: 72px;
    background-image: url(../assets/images/arrow-right.svg);
    background-repeat: no-repeat;
    background-position: 55% 50%;
    background-size: 14px 27px;

    @include mediaMdMax {
      display: none;
    }

    & + .field {
      @include mediaXs {
        margin-top: 30px;
      }
    }
  }

  .submit {
    @include mediaXs {
      margin-top: 40px;
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
    }

    @include mediaSmMin {
      margin-top: 33px;
      width: 350px;
    }

    @include mediaLgMin {
      padding: 0 7px;
      margin-top: 24px;
    }
  }
</style>
