<template>
  <img
    alt="Vue logo"
    src="../assets/images/logo.svg"
    :class="$style.component"
  />
</template>

<script>
export default {
  name: 'Logo'
};
</script>

<style module lang="scss">
  .component {
    max-width: 100%;
  }
</style>
