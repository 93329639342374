<template>
  <div
    v-click-outside="close"
    :class="$style.component"
  >
    <button
      type="button"
      :class="$style.button"
      @click="toggleOpen"
    >
      English
    </button>

    <div
      v-if="open"
      :class="$style.dropdown"
    >
      <div
        v-for="item in list"
        :key="item.url"
        :class="$style.item"
      >
        <a
          v-if="!item.disabled"
          :href="item.url"
          :class="$style.link"
        >
          {{item.title}}
        </a>

        <span
          v-if="item.disabled"
          :class="$style.link"
        >
          {{item.title}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'LangSwitch',
  data: () => ({
    open: false,
    list: [
      {
        title: 'English',
        url: '/en/',
        disabled: true // @TODO add disabled condition
      },
      {
        title: 'Русский',
        url: '/ru/',
        disabled: false
      },
      {
        title: 'Español',
        url: '/es/',
        disabled: false
      },
      {
        title: 'Italiano',
        url: '/it/',
        disabled: false
      },
      {
        title: 'Français',
        url: '/fr/',
        disabled: false
      },
      {
        title: 'Deutsche',
        url: '/de/',
        disabled: false
      },
      {
        title: 'Português',
        url: '/pt/',
        disabled: false
      }
    ]
  }),

  methods: {
    toggleOpen() {
      this.open = !this.open;
    },

    close() {
      this.open = false;
    }
  },

  mounted() {
    this.popupItem = this.$el;
  },

  directives: {
    ClickOutside
  }
};
</script>

<style module lang="scss">
  .component {
    position: relative;
  }

  .button {
    @include resetButtonStyle;

    padding: 5px 15px 5px 23px;
    font-weight: 600;
    background: 0 50% / 14px 14px no-repeat url(../assets/images/globe.svg),
                100% 56% / 10px 5px no-repeat url(../assets/images/chevron.svg);
  }

  .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 50;
    margin-top: 10px;
    padding: 15px 8px;
    background-color: $white;
    border-style: solid;
    border-width: 1px;
    border-color: $black;
    border-radius: 10px;
    animation: appear 0.5s;
  }

  .link {
    display: inline-block;
    min-width: 100%;
    padding: 9px 16px 7px;
    font-weight: 600;
    line-height: 1.25;
    text-decoration: none;
    color: $black;
    border-radius: 4px;
  }

  span.link {
    opacity: 0.3;
  }

  a.link {
    transition: background-color 0.15s ease;

    &:hover {
      background-color: $whiteD;
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(-15%);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
</style>
