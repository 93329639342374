<template>
  <button
    :type="type"
    :class="$style.component"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  inheritAttrs: false,
  props: {
    type: { type: String, required: false, default: 'button' }
  }
};
</script>

<style module lang="scss">
  .component {
    @include resetButtonStyle;

    width: 100%;
    height: 48px;
    padding: 11px 10px 9px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.75;
    color: $black;
    background-color: $white;
    border-radius: 4px;
    transition: background 0.2s ease;

    @include mediaSmMin {
      height: 72px;
      font-size: 24px;
      line-height: 1.16666;
      padding: 22px 20px;
    }

    &:hover {
      background-color: $purpl;
    }
  }
</style>
