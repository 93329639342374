<template>
  <a
    v-bind:href="url"
    :class="$style.component"
  >
    <div :class="$style.icon">
      <img
        v-bind:alt="`${name} icon`"
        v-bind:src="require(`../assets/images/coins/${symbol}.png`)"
      >
    </div>

    <div :class="$style.label">
      {{label}}
    </div>
  </a>
</template>

<script>
export default {
  name: 'CoinLink',
  props: {
    symbol: { type: String, required: true },
    name: { type: String, required: true },
    url: { type: String, required: true },
    label: { type: String, required: true }
  }
};
</script>

<style module lang="scss">
.component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  text-decoration: none;

  &:hover,
  &:focus {
    .label {
      border-color: $white;
    }
  }

  @include mediaSmMin {
    max-width: 240px;
    margin: 0 auto;
    justify-content: flex-start;
  }

  @include mediaXl {
    margin: 0;
    max-width: initial;
    padding: 10px 24px;
  }
}

.icon {
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  margin-right: 12px;

  img {
    width: 16px;
    height: 16px;
  }
}

.label {
  display: inline-block;
  font-size: 18px;
  line-height: 1.5;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: $gray;
  transition: border-color 0.2s ease;
}
</style>
