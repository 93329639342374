<template>
  <div :class="$style.component">
    <label
      :for="id"
      :class="$style.label"
    >
      {{label}}
    </label>

    <div
      :class="[$style.inputHolder, {
        [$style.inputFocused]: focused,
        [$style.inputError]: error,
      }]"
    >
      <input
        type="number"
        :id="id"
        :name="name"
        v-model="currentValue"
        placeholder="0.00"
        inputmode="decimal"
        step="0.01"
        pattern="^[0-9]+([\.][0-9]+)?$"
        :class="$style.input"
        @focus="onFocus"
        @blur="onBlur"
      />

      <span :class="$style.currency">
        {{currency}}
      </span>
    </div>

    <div
      v-if="suggestions.length && !error"
      :class="$style.suggestions"
    >
      <div
        v-for="suggestion in suggestions"
        :key="suggestion"
        :class="$style.suggestion"
      >
        <button
          type="button"
          :class="$style.suggestionButton"
          @click="setValue(suggestion)"
        >
          {{suggestion}}
        </button>
      </div>
    </div>

    <div
      v-if="error"
      :class="$style.error"
    >
      {{error}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: {
    id: { type: String, required: false, default: '' },
    name: { type: String, required: false, default: '' },
    label: { type: String, required: true },
    value: { type: Number, required: false, default: null },
    currency: { type: String, required: true },
    suggestions: { type: Array, required: false, default: () => [] },
    error: { type: String, required: false, default: '' }
  },

  data() {
    return {
      currentValue: this.value,
      focused: false
    };
  },

  methods: {
    onFocus() {
      this.focused = true;
    },

    onBlur() {
      this.focused = false;
    },

    setValue(val) {
      this.currentValue = val;
    }
  },

  watch: {
    currentValue(val) {
      const value = parseFloat(val, 10);
      this.$emit('change', val ? value : null, this.name);
    },

    value(val) {
      if (this.focused) {
        return;
      }

      this.currentValue = val;
    }
  }
};
</script>

<style module lang="scss">
  .label {
    margin-bottom: 4px;
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .inputHolder {
    width: 100%;
    display: flex;
    border-radius: 6px;
    box-shadow: inset 0 0 0 2px $white;
    transition: box-shadow 0.15s ease;
  }

  .inputError {
    box-shadow: inset 0 0 0 2px $red;
  }

  .inputFocused {
    box-shadow: inset 0 0 0 1px $white;

    &.inputError {
      box-shadow: inset 0 0 0 1px $red;
    }
  }

  .input {
    width: 100%;
    height: 48px;
    max-width: calc(100% - 88px);
    padding: 12px 7px 8px 17px;
    font: inherit;
    font-size: 16px;
    font-weight: 600;
    color: $white;
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;

    @include mediaSmMin {
      height: 72px;
      padding: 24px 10px 20px 20px;
      font-size: 24px;
      max-width: calc(100% - 92px);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  .currency {
    margin: 6px 6px 6px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    font-weight: 600;
    font-size: 16px;
    background-color: $grayXD;
    border-radius: 4px;

    @include mediaSmMin {
      margin: 10px 10px 10px 0;
      font-size: 24px;
    }
  }

  .suggestions {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px;
  }

  .suggestion {
    padding: 0 4px;
    width: 100%;
  }

  .suggestionButton {
    @include resetButtonStyle;

    width: 100%;
    text-align: center;
    height: 36px;
    font-size: 12px;
    font-weight: 600;
    background: $blackL;
    border-radius: 4px;
    transition: background 0.2s ease;

    &:hover {
      background: $grayXD;
    }
  }

  .error {
    margin-top: 4px;
    min-height: 44px;
    font-size: 12px;
    line-height: 1.3333;
    color: $red;
  }
</style>
